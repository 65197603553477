<template>
<div :style="activityStyle" class="view w-full h-full flex z-50 m-auto absolute inset-0 p-0">
  <div id="activity-view" class="flex relative m-auto">
    <div  :style="valueStyling" class="flex justify-center items-center w-screen h-screen m-0 fixed inset-0 transform scale-400" v-if="style && style.data && style.data.ring">
      <div class="flex justify-center items-center">
        <div class="">
                    <circle-progress 
                    :percent="stateActiveEnergyBurned / style.data.ring.goalCalories * 100" :fill-color="circleFillColor" :empty-color="(stateActiveEnergyBurned / style.data.ring.goalCalories > 1.0) ? circleFillColor : circleEmptyColor" />
                </div>
      <div  class="absolute  flex content-center items-center">
          <span class="flex">{{ wholeActiveEnergyBurned }}</span
          ><span class="flex text-sm self-end p-2">CALS</span>
        
      </div>
        </div>
    </div>
      <div v-else>
      <div class="flex flex-col  items-center mx-2">
        <div class="flex flex-row items-center mx-2">
        <logo class="flex-1 flex-shrink-0" style="width: 200px; height: 200px" />
        <div class="flex justify-self-start justify-start items-baseline">
          <span :style="valueStyling" class="flex-none w-80 ml-2 flex-shrink-0 text-right">{{ wholeHeartRate }}</span
          ><span class="flex-none ml-2 flex-shrink-0 text-9xl">BPM</span>
        </div>
        </div>
        <div class=" flex-row text-4xl font-black items-center absolute flex bottom-8 ">
          <logo class="flex-1 flex-shrink-0 h-4 w-4 m-2"  />
          <div class="font-sans">heartwitch.app</div>
        </div>
      </div>
      <div v-if="size" id="activity-size" class="fixed top-0 font-mono text-xl hidden">
        <div>{{ size.view.width + 275 }} x {{ size.view.height + 100 }}</div>
      </div>
      </div>
  </div>
    </div>
</template>
<script>
      import "vue3-circle-progress/dist/circle-progress.css";
    import CircleProgress from "vue3-circle-progress";
import { mapState } from 'vuex'
import CloseIcon from '@/components/CloseIcon.vue'
import FullScreenIcon from '@/components/FullScreenIcon.vue'
import ConfigureIcon from '@/components/ConfigureIcon.vue'
import Logo from '@/components/Logo.vue'
const WebFont = require('webfontloader')

const fonts = {
  Oxygen: 1.0,
  'Passion One': 1.1,
  'Special Elite': 1.0,
  Lobster: 1.0,
  'Oxygen Mono': 0.8,
  'Press Start 2P': 0.7,
  Iceland: 1.0,
  'Henny Penny': 0.8,
  Orbitron: 0.8,
  'Mountains of Christmas': 1.0,
}

export default {
  components: {
    Logo,
    CloseIcon,
    FullScreenIcon,
    CircleProgress
  },
  props: ['initial','styleID', 'sourceID'],
  data() {
    return {
      data: null,
      timer: '',
      style : null,
      fonts,
      size: null
    }
  },
  computed: {
    ...mapState({
      socketConnected: (state) => state.socket.isConnected,
      stateHeartRate: (state) => state.workoutData.heartRate,
      stateActiveEnergyBurned: (state) => state.workoutData.activeEnergyBurned
    }),
    circleFillColor() {
      return this.hexcodeFromColorValue(
          this.style.data.ring.foregroundColor
        )
    },
    circleEmptyColor() {
      const mod = this.style.data.ring.foregroundColor % 256
      const value = (this.style.data.ring.foregroundColor - mod + 256 * 0.25)
      return this.hexcodeFromColorValue(
        value
      )
    },
    wholeHeartRate() {
      const heartRate = this.stateHeartRate || this.initial.heartRate
      return Math.round(heartRate)
    },
    wholeActiveEnergyBurned() {
      const activeEnergyBurned = this.stateActiveEnergyBurned || this.initial.activeEnergyBurned
      return Math.round(activeEnergyBurned)
    },
    classObject() {
      const result = {
        configuring: this.type === 'configure',
        full: this.type === 'full',
        initial: this.type === 'initial',
      }
      result[this.fontClass] = true
      return result
    },
    fontClass() {
      if (
        this.styling.fontFamily &&
        fontClasses[this.unquote(this.styling.fontFamily)]
      ) {
        return 'fs-' + fontClasses[this.unquote(this.styling.fontFamily)]
      } else {
        return 'fs-normal'
      }
    },
    heartRate() {
      if (this.type === 'configure') {
        return this.socketConnected ? this.stateHeartRate : this.fakeHeartRate
      } else {
        return this.stateHeartRate
      }
    },
    activityStyle() {
      const style = this.style

      if (!style) {
        return null
      }
      if (style.data.simple){
      return {
        backgroundColor: this.hexcodeFromColorValue(
          style.data.simple.backgroundColor
        ),
        color: this.hexcodeFromColorValue(
          style.data.simple.textColor
        ),
        fontFamily: this.quote(this.style.data.simple.fontFamily),
        fontSize:
          200 * this.fonts[this.style.data.simple.fontFamily] + 'px',
      }
    }
    },
    valueStyling() {

      const style = this.style

      if (!style) {
        return {}
      }

      if (style.data) {
        if (style.data.simple) {
          
          const fontFamily = style.data.simple.fontFamily

          if (!fontFamily) {
            return {}
          }

          const factor = this.fonts[fontFamily]  || 1.0

          const widthValue = 20 / factor

          return {
            width: widthValue + "rem"
          }
        } else if (style.data.ring) {
          return {
            backgroundColor: this.hexcodeFromColorValue(
              this.style.data.ring.backgroundColor
            ),
            color: this.hexcodeFromColorValue(
              this.style.data.ring.foregroundColor
            ),
            fontFamily: this.quote(this.style.data.ring.fontFamily),
            fontSize:
              42 * this.fonts[this.style.data.ring.fontFamily] + 'px',
          }
        } else {
          return {}
        }
      }
    }
  },
  mounted() {
    WebFont.load({
      google: {
        families: Object.keys(this.fonts),
        text: '012345678909.: BPMCALS',
      },
      active() {
        this.fontsReady = true
      },
    })
    this.loadSelectedStyle()
    const viewEl = this.$el.querySelector("#activity-view")
    this.size = {
      window : {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      view : {
        width: viewEl.scrollWidth,
        height: viewEl.scrollHeight
      }
    }
    window.onresize = () => {
      this.size.window = {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    }
  },
  methods: {
    updateHeartRate() {
      const value = Math.random() * 200
      this.fakeHeartRate = value
    },
    unquote(str) {
      const firstChar = str.charAt(0)
      const lastChar = str[str.length - 1]
      // double quotes
      if (firstChar && lastChar === String.fromCharCode(34)) {
        str = str.slice(1, -1)
      }
      // single quotes
      if (firstChar && lastChar === String.fromCharCode(39)) {
        str = str.slice(1, -1)
      }
      return str
    },
    loadSelectedStyle() {
      const id = this.styleID
      if (!id) {
        this.style = {
            name: '',
            data: {
              simple: {
                fontFamily: 'Oxygen',
                backgroundColor: 0x0000_00ff,
                textColor: 0xffff_ffff,
              },
            },
          }
          return
      }
      
      const url = '/api/v1/' + (this.sourceID ? ['sources', this.sourceID, 'styles', id].join('/') : ('styles/'+id))
      const headers = this.$store.getters.token ? {
            Authorization: 'Bearer ' + this.$store.getters.token,
          } : null
        this.axios
        .get(url, {
          headers,
        })
        .then((response) => {
          const style = response.data
          if (!style) {
            return
          }
          this.style = style
        })
      
    },
    quote(value) {
      if (value.includes(' ')) {
        return '"' + value + '"'
      } else {
        return value
      }
    },
    hexcodeFromColorValue(colorValue) {
      return '#' + colorValue.toString(16).padStart(8, '0').toUpperCase()
    },
  },
}
</script>
<style scoped>
.view {
  @apply z-50 m-auto p-4;
}
</style>
