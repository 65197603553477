import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueNativeSock from 'vue-native-websocket-vue3'

require('typeface-oxygen')
import './index.css'

const scheme = window.location.protocol === 'https:' ? 'wss' : 'ws'
const baseUrl = `${scheme}://${window.location.host}`

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueNativeSock, baseUrl + 'api/v1/workouts/', {
    format: 'json',
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 3000,
    store,
    connectManually: true,
  })
  .mount('#app')
