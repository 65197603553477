<template>
  <div>
    <div>
      <logo class="m-auto" />
      <h1 class="title">Heartwitch</h1>
      <h2 class="subtitle">
        Live Stream Your Health Stats Right From Your Apple Watch
      </h2>
    </div>
    <login v-if="!authenticated" />
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import Login from '@/components/Login.vue'

export default {
  components: {
    Logo,
    Login,
  },
  computed: {
    authenticated() {
      return this.$store.getters.authenticated
    },
  },
  mounted() {
    this.$store.commit('initialize')
    if (this.authenticated) {
      this.$router.push({ path: '/home' })
    }
  },
}
</script>

<style>
h1.title {
  @apply text-5xl text-center;
}

h2.subtitle {
  @apply text-xl font-bold text-center;
}
</style>
