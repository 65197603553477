<template>
  <div
    id="about"
    class="container mx-auto"
  >
    <main class="mb-5 p-5">
      
      <h1 id="contact-info">
        Contact Info
      </h1>
      <section>
        <h2>Leo Dion</h2>
        <a href="mailto:leo@heartwitch.app">leo@heartwitch.app</a><br>
        <a href="https://twitter.com/leogdion">@leogdion</a>
      </section>
    </main>
  </div>
</template>
