<template>
  <div class="h-10 flex flex-row space-x-2 space-y-2 items-center justify-start justify-items-start" v-if="style">
    <button class="flex p-1 bg-white text-black rounded text-xs mt-2" @click="$emit('styleEdit', style)">
      <svg class="h-4"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
      </svg>
      <div>
        Edit
      </div>
    </button>
    <div class="flex w-1/4">
      {{ label }}
    </div>
    <div class="flex h-full items-center" :style="itemStyling">
      <div class="px-4 text-center">
        120BPM
      </div>
    </div>
    <div class="flex flex-row space-x-2" v-if="this.style.isActive">
      <svg class="h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clip-rule="evenodd" />
      </svg>
      <div class="text-xs">
        In Use
      </div>
    </div>
    <div v-else>
    <button class="flex p-1 bg-white text-black rounded text-xs" @click="$emit('styleDelete', style)">

      <svg class="h-4"   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
</svg>
      <div>
        Delete
      </div>
    </button>
    </div>
  </div>
</template>
<script>

const fonts = {
  Oxygen: 1.0,
  'Passion One': 1.2,
  'Special Elite': 1.0,
  Lobster: 1.0,
  'Oxygen Mono': 0.8,
  'Press Start 2P': 0.6,
  Iceland: 1.0,
  'Henny Penny': 0.8,
  Orbitron: 0.8,
  'Mountains of Christmas': 1.0,
}

export default {
  props: ['style'],
  emits: ['styleEdit', 'styleDelete'],
  data() {
    return { fonts }
  },
  computed: {
    label() {
      if (!this.style) {
        return ''
      }

      if (this.style.name.trim() !== '') {
        return this.style.name
      }

      if (this.style.summary && this.style.summary.fontFamily) {
        return this.style.summary.fontFamily
      }

      return ''
    },
    itemStyling() {
      if (!this.style) {
        return
      }

      return {
        fontFamily : this.quote(this.style.summary.fontFamily),
        backgroundColor : this.hexcodeFromColorValue(this.style.summary.secondaryColor),
        color : this.hexcodeFromColorValue(this.style.summary.primaryColor),
        fontSize:          18 * this.fonts[this.style.summary.fontFamily] + 'px',
      }
    }
  },
  methods: {
    hexcodeFromColorValue(colorValue) {
      return '#' + colorValue.toString(16).padStart(8, '0').toUpperCase()
    },
    quote(value) {
      if (value.includes(' ')) {
        return '"' + value + '"'
      } else {
        return value
      }
    },

  },
}
</script>
