<template>
  <svg
    enable-background="new 0 0 64 64"
    height="200"
    viewBox="-8 -8 80 80"
    width="200"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m57.68 8.979c-7.09-7.09-18.59-7.09-25.68 0-7.09-7.09-18.59-7.09-25.68 0-7.09 7.091-7.09 18.591 0 25.681l25.68 25.679 25.68-25.68c7.09-7.09 7.09-18.59 0-25.68z"
      fill="#ed5565"
    />
    <path
      d="m16.18 19.159c1.09 1.08 1.09 2.85 0 3.93l-.239.25-3.941 3.94-3.94-3.94-.25-.25c-1.079-1.08-1.079-2.85 0-3.93 1.091-1.09 2.86-1.09 3.94 0l.25.24.25-.24c1.08-1.09 2.85-1.09 3.93 0z"
      fill="#da4453"
    />
    <path
      d="m56.18 19.159c1.09 1.08 1.09 2.85 0 3.93l-.239.25-3.941 3.94-3.94-3.94-.25-.25c-1.079-1.08-1.079-2.85 0-3.93 1.08-1.09 2.851-1.09 3.94 0l.25.24.24-.24c1.09-1.09 2.86-1.09 3.94 0z"
      fill="#da4453"
    />
    <path
      d="m33 28.32v-3.004c0-.552.448-1 1-1h6.002c.004 0 .006-.002.01-.002 1.648-.005 2.988-1.346 2.988-2.997s-1.34-2.991-2.988-2.997c-.004 0-.006-.002-.01-.002h-16.007c-.551 0-.998-.447-.998-.998s.447-.998.998-.998c.003 0 .006-.002.01-.002h5.991c1.654 0 3-1.346 3-3v-5.242c-.337.291-.676.58-.996.9-.322-.322-.664-.614-1.004-.907v5.249c0 .552-.448 1-1 1h-6.001c-.007 0-.013.004-.02.004-1.645.011-2.979 1.35-2.979 2.996 0 1.653 1.345 2.998 2.998 2.998h16.002c.002 0 .003.001.005.001.551 0 .998.447.998.998s-.447.998-.998.998c-.002 0-.003.001-.005.001h-5.996c-1.654 0-3 1.346-3 3v3.004c0 .553.447 1 1 1s1-.447 1-1z"
      fill="#656d78"
    />
    <path
      d="m39 28.319c3.87 0 7 3.13 7 7 0 3.869-3.13 7-7 7-2.79 0-5.19-1.641-6.32-4h-1.36c-1.13 2.359-3.53 4-6.32 4-3.87 0-7-3.131-7-7 0-3.87 3.13-7 7-7h7z"
      fill="#e6e9ed"
    />
    <g fill="#656d78">
      <path
        d="m25 38.319c-.553 0-1-.447-1-1v-4c0-.553.447-1 1-1s1 .447 1 1v4c0 .553-.447 1-1 1z"
      />
      <path
        d="m27 36.319h-4c-.553 0-1-.447-1-1s.447-1 1-1h4c.553 0 1 .447 1 1s-.447 1-1 1z"
      />
      <path
        d="m37.044 36.319c-.553 0-1.005-.447-1.005-1s.442-1 .994-1h.011c.552 0 1 .447 1 1s-.448 1-1 1z"
      />
      <path
        d="m39.044 34.319c-.553 0-1.005-.447-1.005-1s.442-1 .994-1h.011c.552 0 1 .447 1 1s-.448 1-1 1z"
      />
      <path
        d="m39.046 38.32c-.553 0-1.005-.447-1.005-1s.442-1 .994-1h.011c.552 0 1 .447 1 1s-.448 1-1 1z"
      />
      <path
        d="m41.046 36.32c-.553 0-1.005-.447-1.005-1s.442-1 .994-1h.011c.552 0 1 .447 1 1s-.448 1-1 1z"
      />
    </g>
  </svg>
</template>
