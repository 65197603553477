import { createRouter, createWebHashHistory } from 'vue-router'
import Start from '@/pages/index.vue'
import Home from '@/pages/home.vue'
import HomeIndex from '@/pages/home/index.vue'
import Success from '@/pages/home/success.vue'
import Profile from '@/pages/home/profile.vue'
import Source from '@/pages/source.vue'
import PrivacyPolicy from '@/pages/privacy-policy.vue'
import About from '@/pages/about.vue'
import ContactInfo from '@/pages/contact-info.vue'
import Terms from '@/pages/terms.vue'
import ResetPassword from '@/pages/reset-password.vue'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Start',
    component: Start,
  },
  {
    path: '/source/:id',
    name: 'Source',
    component: Source
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/contact-info',
    name: 'ContactInfo',
    component: ContactInfo
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/reset-password/:code',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/home',
    name: 'Main',
    component: Home,
    children: [
      {
        path: '',
        component: HomeIndex,
      },
      {
        path: 'profile',
        component: Profile,
      },
      {
        path: 'success/:id',
        component: Success,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  /*{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about"
  } */
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.commit('initialize')
    if (!store.getters.authenticated) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath },
      })
    }
  }
  next()
})

export default router
