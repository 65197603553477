<template>
<div>
  <div v-if="profile" class="profile">
    <div>
      <svg
        class="h-6 w-6 float-left mr-2 flex-none"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
      Settings
    </div>
    <div>
      <h2 class="text-xl">{{ email }}</h2>
    </div>
    <div v-if="source">
      {{ source.hash }}
    </div>
    <hr>
    <div>
      <svg
        class="h-6 w-6 float-left mr-2 flex-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
        <path
          fill-rule="evenodd"
          d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
          clip-rule="evenodd"
        />
      </svg>
      Your Plan
    </div>
    <hr>
    <div>
      <div v-if="profile.plan == 'premium'">
        <img
          src="/img/badges/crown.svg"
          class="h-6 w-6 inline-block align-baseline mr-2"
        />
        <span class="text-xl">Heartwitch Premium</span>
        <div>
          <span>billed monthly</span>&nbsp;<span>until </span>
          <span>1/27/2022</span>
        </div>
        <ul class="text-xs mt-2">
          <li>Unlimited Streams</li>
          <li>Static Browser Source URL</li>
          <li>Multiple Saved Styles</li>
        </ul>
      </div>
      <div v-else>
        <img
          src="/img/badges/trophy.svg"
          class="h-6 w-6 inline-block align-baseline mr-2"
        />
        <span class="text-xl">Heartwitch Starter</span>
        <div>
          <span>Free Plan</span>
        </div>        
      </div>
    </div>
    <div v-if="requiresUpgrade">
      <button @click="modal = 'productsUpgrade'" class="inline-flex p-1 bg-white text-black rounded items-center text-sm">
          <svg  class="inline h-4 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
          Upgrade to Premium
        </button>
    </div>
    <div v-else>
      <div>
        <button @click="manageBilling()">Manage Billing</button>
      </div>
    </div>
    <hr>
    <div>
      <svg
        class="h-6 w-6 float-left mr-2 flex-none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
          clip-rule="evenodd"
        />
      </svg>
      Your Devices
      <a
        v-if="redirectURL"
        class="inline-flex p-1 mx-2 bg-white text-black rounded items-center text-sm"
        target="_blank"
        :href="redirectURL"
        @click="openRedirect($event)"
      >
        <img class="inline h-4 mr-1" src="/img/apple.svg" />
        <span>Link With Apple</span>
      </a>
    </div>
    <hr>
    <div v-if="profile.devices">
      <div v-for="device in profile.devices" :key="device.identifier">
        <button class="w-24" @click="toggleRegistration(device)">
          <img
            class="h-5 inline-block"
            :src="buttonImageForDevice(device)"
            title="Register Device"
          />
          {{ buttonTextForDevice(device) }}
        </button>
        {{ device.name }}
      </div>
    </div>
    <hr>
    <div>
      <svg
        class="h-6 w-6 float-left mr-2 flex-none"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        />
      </svg>
      <span>Your Styles</span>
      <button v-if="requiresUpgrade || canAddStyle" @click="newStyle()" class="inline-flex p-1 mx-2 bg-white text-black rounded items-center text-sm">
        <svg class="h-4 inline mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
        </svg> 
        <span class="align-middle">Add New Style</span>
        </button>
        <button v-if="requiresUpgrade || canAddStyle" @click="newRingStyle()" class="inline-flex p-1 mx-2 bg-white text-black rounded items-center text-sm">
        <svg class="h-4 inline mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
        </svg> 
        <span class="align-middle">Add Activity Ring</span>
        </button>
    </div>
    <hr>
    <div v-if="styles">
      <div v-if="styles.length > 0" >
      <style-item :style="style" v-for="style in styles" :key="style.id" @style-delete="deleteStyleWithID(style.id)" @style-edit="editStyle" />
      </div>
      <div v-else>
        No Existing Styles
      </div>
    </div>
    <hr>
    <div>
      <svg
        class="h-6 w-6 float-left mr-2 flex-none"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
      Your Streams
    </div>
  </div>
    <hr>
    <report :profile="profile"/>

    <div v-if="selectedStyle">
      <div class="fixed h-screen w-screen inset-0">
        <div
          class="bg-black h-full w-full opacity-70 absolute"
          @click="saveStyle(selectedStyle)"
        ></div>
        <div class="flex w-full h-full justify-center content-center">
          <ring-style-view v-if="selectedStyle && selectedStyle.data && selectedStyle.data.ring" @completed-with-changes="stylingHasCompletedWithChanges" :id="selectedStyleId" :style="selectedStyle" :source="styleViewSource"></ring-style-view>
          <simple-style-view v-else @completed-with-changes="stylingHasCompletedWithChanges" :id="selectedStyleId" :style="selectedStyle" :source="styleViewSource"></simple-style-view>
        </div>
      </div>
    </div>
    <div v-else-if="modal == 'productsUpgrade'">
      <div class="fixed h-screen w-screen inset-0">
        <div
          class="bg-black h-full w-full opacity-70 absolute"
          @click="modal = null; plansMessage = null"
        ></div>
        <div class="flex w-full h-full justify-center content-center">
          <plans @done="modal = null; plansMessage = null" :message="plansMessage" :products="products"></plans>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import integer from '../../utils/integer'
import StyleItem from '@/components/StyleItem'
import SimpleStyleView from '@/components/SimpleStyleView'
import RingStyleView from '@/components/RingStyleView'
import Plans from '@/components/Plans'
import Report from '@/components/Report.vue'
const WebFont = require('webfontloader')

const fonts = {
  Oxygen: 1.0,
  'Passion One': 1.2,
  'Special Elite': 1.0,
  Lobster: 1.0,
  'Oxygen Mono': 0.8,
  'Press Start 2P': 0.6,
  Iceland: 1.0,
  'Henny Penny': 0.8,
  Orbitron: 0.8,
  'Mountains of Christmas': 1.0,
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export default {
  components: {
    // Workout,
    // Activity,
    // Configure,
    // ConfigureIcon,
    SimpleStyleView,
    RingStyleView,
    Plans,
    StyleItem,
    Report,
  },
  middleware: 'auth',
  data() {
    return {
      fontsReady: false,
      today: new Date(),
      profile: null,
      fonts,
      publishableKey: process.env.VUE_APP_PUBLISHABLE_KEY,
      modal: null,
      billingMonthly: false,
      redirectURL: null,
      detailedDevices: null,
      styles: null,
      sources: null,
      selectedStyle: null,
      selectedStyleId: null,
      modal: null,
      products: null,
      plansMessage: null
    }
  },
  computed: {
    ...mapState({
      stateStyling: (state) => state.styling,
    }),
    source() {
      if (this.profile) {
        return this.profile.sourceURL
      }
      return undefined
    },
    styleViewSource() {
      if (this.styles.length > 1) {
        return this.source
      }
      return undefined
    },
    email() {
      if (this.profile.email) {
        return this.profile.email
      } else {
        return this.$store.getters.email
      }
    },
    billingAnnually: {
      get: function () {
        return !this.billingMonthly
      },
      set: function (newVal) {
        this.billingMonthly = !newVal
      },
    },
    requiresUpgrade() {
      if (!this.profile) {
        return true
      }
      const plan = this.profile.plan
      return plan == 'starter'
    },
    monthName() {
      return monthNames[this.today.getMonth()]
    },
    workoutReport() {
      const profile = this.profile

      const plan = (profile && profile.plan) || 'starter'

      const workouts = (profile && profile.workouts) || []

      const monthCount = this.workoutCount
      const monthMS = workouts
        .map(
          (workout) => new Date(workout.updatedAt) - new Date(workout.createdAt)
        )
        .reduce((total, current) => total + current, 0)
      const maxMS =
        plan == 'unlimited'
          ? Number.POSITIVE_INFINITY
          : 5.0 * 60.0 * 60.0 * 1000.0
      const maxCount = plan == 'unlimited' ? Number.POSITIVE_INFINITY : 10

      return {
        month: {
          count: monthCount,
          ms: monthMS,
        },
        max: {
          count: maxCount,
          ms: maxMS,
        },
      }
    },
    maximumStyles() {
      return this.requiresUpgrade ? 1 : 3
    },
    canAddStyle() {
      const styleCount = this.styles && this.styles.length
      if (typeof styleCount !== 'number') {
        return false
      }
      return styleCount < this.maximumStyles
    },
  },
  watch: {},
  mounted() {    
    WebFont.load({
      google: {
        families: Object.keys(this.fonts),
        text: '012345678909.: BPMCAL',
      },
      active() {
        this.fontsReady = true
      },
    })
    this.$store.commit('initialize')
    this.loadProducts()
    this.loadProfile()
    this.loadStyles()
    this.refreshDevices()
    window.addEventListener('message', this.windowMessage)
  },
  methods: {
    manageBilling() {
      this.axios
        .get('/api/v1/users/portal', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
          },
        })
        .then((response) => {
          const url = response.data.url
          if (!url) {
            return 
          }
          window.location.replace(url)
        })
    },
    deleteStyleWithID(styleID) {
      const baseUrl = '/api/v1/styles'
      const method = 'DELETE'
      const urlSubpath = '/' + styleID
      const url = baseUrl + urlSubpath
        this.isSaving = true
      this.axios({
        method,
        url,
        data: this.currentStyle,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.token,
        },
      }).then(() => {
        this.loadStyles()
      })
    },
    editStyle(style) {
      const id = style.id
      if (id) {
        this.axios
        .get('/api/v1/styles/'+id, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
          },
        })
        .then((response) => {
          const style = response.data
          if (!style) {
            return
          }
          this.selectedStyle = style
          this.selectedStyleId = id
        })
      }
    },
    newStyle() {
       if (this.canAddStyle) {
          this.selectedStyle = {}
          this.selectedStyleId = null
       } else if(this.requiresUpgrade) {
         this.modal = 'productsUpgrade'
         this.plansMessage = 'Add up to 3 styles by upgrading to Premium'
       }
      
    },
    newRingStyle() {
       if (this.canAddStyle) {
          this.selectedStyle = {
            name: '',
            data: {
              ring: {
                fontFamily: 'Oxygen',
                backgroundColor: 0x0000_00ff,
                foregroundColor: 0xffff_ffff,
                goalCalories: 125.0
              },
            },
          }
          this.selectedStyleId = null
       } else if(this.requiresUpgrade) {
         this.modal = 'productsUpgrade'
         this.plansMessage = 'Add up to 3 styles by upgrading to Premium'
       }
      
    },
    openRedirect(event) {
      const newWindow = window.open(this.redirectURL, '_blank')
      newWindow.addEventListener('message', this.windowMessage)
      if (event) {
        event.preventDefault()
      }
    },
    windowMessage(event) {
      const webAuthenticationToken = event && event.data && event.data.ckSession
      if (webAuthenticationToken) {
        this.saveAppleToken(webAuthenticationToken)
      }
    },
    saveAppleToken(webAuthenticationToken) {
      this.axios
        .post(
          '/api/v1/accounts/apple',
          {
            webAuthenticationToken,
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.$store.getters.token,
            },
          }
        )
        .then(() => {
          
            this.refreshDevices()
          
        })
    },
    refreshDevices() {
      this.axios
        .get('/api/v1/devices', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
          },
        })
        .then((info) => {
          const result = info && info.data && info.data.result
          const url = info && info.data && info.data.redirectURL
          if (result) {
            this.redirectURL = null
            this.profile.devices = result
          } else if (url) {
            this.redirectURL = url
          } else {
            throw info
          }
        })
    },
    loadProducts() {
      this.axios.get('/api/v1/products').then((response) => {
        const products = response.data
        if (!products) {
          return
        }
        this.products = products
      })
    },
    loadProfile() {
      this.axios
        .get('/api/v1/users', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
          },
        })
        .then((response) => {
          const profile = response.data
          if (!profile) {
            return
          }
          this.profile = profile
          this.lastCall = new Date()
          if (!this.timerID) {
            this.timerID = setInterval(this.invalidate, 1000)
          }
        })
    },
    loadStyles() {
      if (this.stateStyling) {
        const simple = {
          textColor: integer(this.stateStyling.color),
          backgroundColor: integer(this.stateStyling.backgroundColor),
          fontFamily: this.stateStyling.fontFamily,
        }
        const name = ''
        this.axios
          .post(
            '/api/v1/styles',
            {
              name,
              data: {
                simple,
              },
            },
            {
              headers: {
                Authorization: 'Bearer ' + this.$store.getters.token,
              },
            }
          )
          .then(() => {
            this.$store.commit('clearStyling')
            this.loadStyles()
          })
        return
      }
      this.axios
        .get('/api/v1/styles', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
          },
        })
        .then((response) => {
          const styles = response.data
          if (!styles) {
            return
          }
          this.styles = styles
        })
    },
    loadSources() {
      this.axios
        .get('/api/v1/sources', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
          },
        })
        .then((response) => {
          const sources = response.data
          if (!sources) {
            return
          }
          this.sources = sources
        })
    },
    buttonImageForDevice(device) {
      if (device.isRegistered) {
        return '/img/remove.svg'
      } else {
        return '/img/add.svg'
      }
    },
    buttonTextForDevice(device) {
      if (device.isRegistered) {
        return 'Remove'
      } else {
        return 'Register'
      }
    },
    toggleRegistration(device) {
      this.axios({
        method: device.isRegistered ? 'DELETE' : 'POST',
        url: '/api/v1/devices',
        data: {
          identifiers: [device.identifier],
        },
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.token,
        },
      }).then(() => {
        device.isRegistered = !device.isRegistered
      })
    },
    stylingHasCompletedWithChanges(changed) {
      if (changed) {
        this.loadStyles()
      }
      this.selectedStyle = null
      this.selectedStyleId = null
      
    }
  },
}
</script>
<style scoped>
.profile > div {
  @apply my-4;
}
.profile > div:first-child {
  @apply my-0;
}
</style>
