<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 357.18 357.18"
    style="enable-background: new 0 0 357.18 357.18"
    xml:space="preserve"
    width="512px"
    height="512px"
    class=""
  >
    <g>
      <g>
        <g>
          <path
            d="M123.292,96.7c-0.128-0.196-0.112-0.408-0.284-0.58L65.456,38.564l31.268,0.048c7.396,0,13.82-6.42,13.816-13.812    l0.004-10.524c0-7.648-6.22-13.868-13.864-13.868H15.04C11.264,0.408,7.7,2,5.072,4.68c-2.608,2.596-4.164,6.088-4.164,9.86V97.9    c-0.004,7.64,6.216,13.86,13.86,13.86l10.528,0.008c7.636,0,13.848-6.212,13.852-13.852l-0.02-31.32L89,116.472    c0.028,0.024,0.052,0.048,0.072,0.072l6.428,6.42c5.404,5.408,14.104,5.284,19.512-0.124l7.44-7.436    C127.576,110.272,127.884,102.144,123.292,96.7z"
            data-original="#000000"
            class="active-path"
            data-old_color="#000000"
            :fill="color"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M357.132,260.884c0-7.648-6.212-13.876-13.856-13.876L332.756,247c-7.384-0.008-13.836,6.444-13.836,13.836l0.048,31.272    L261.64,234.78c-0.18-0.18-0.5-0.268-0.696-0.4c-5.444-4.6-13.688-4.396-18.812,0.728l-7.444,7.444    c-5.4,5.4-5.316,14.32,0.1,19.736l6.42,6.42c0.024,0.024,0.048,0.048,0.072,0.072l49.656,49.656l-31.312-0.016    c-7.384,0-13.852,6.46-13.852,13.852l0.024,10.544c-0.008,7.64,6.228,13.876,13.868,13.868l83.38,0.032    c3.688-0.008,7.196-1.4,9.824-4.032l0.376-0.376c2.532-2.54,3.936-6.048,3.936-9.728L357.132,260.884z"
            data-original="#000000"
            class="active-path"
            data-old_color="#000000"
            :fill="color"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M122.308,242.804l-7.448-7.452c-5.12-5.116-13.412-5.24-18.852-0.648c-0.196,0.132-0.56,0.296-0.736,0.468l-57.084,57.084    l0.036-31.288c0.004-3.684-1.42-7.14-4.04-9.764c-2.624-2.62-6.104-4.072-9.792-4.064l-10.524-0.008    c-3.824,0-7.288,1.556-9.8,4.072C1.552,253.712,0,257.18,0,261v81.64c0,3.78,1.52,7.32,4.272,9.968    c2.544,2.648,6.084,4.164,9.86,4.164h83.36c7.644,0.008,13.864-6.212,13.864-13.852v-10.532c0-3.688-1.436-7.156-4.064-9.78    c-2.62-2.628-6.084-4.056-9.772-4.056l-31.296,0.024l49.412-49.404c0.024-0.024,0.044-0.04,0.064-0.064l5.584-5.584l0.84-0.844    C127.532,257.272,127.708,248.204,122.308,242.804z"
            data-original="#000000"
            class="active-path"
            data-old_color="#000000"
            :fill="color"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M356.348,14.576c0-3.772-1.516-7.312-4.264-9.964c-2.556-2.648-6.096-4.172-9.868-4.172h-81.64    c-3.82,0-7.288,1.556-9.796,4.064c-2.516,2.516-4.072,5.976-4.072,9.796v10.524c0,3.696,1.44,7.164,4.064,9.788    c2.616,2.62,6.084,4.056,9.772,4.056l31.288-0.04l-57.4,57.4c-0.172,0.18-0.312,0.516-0.444,0.712    c-4.592,5.444-4.436,13.704,0.68,18.82l7.452,7.452c5.4,5.4,14.412,5.276,19.82-0.132l6.42-6.42    c0.032-0.024,0.048-0.048,0.072-0.072l49.728-49.724l-0.032,31.296c0,3.692,1.416,7.14,4.04,9.756    c2.624,2.636,6.108,4.08,9.788,4.072l10.532,0.008c7.648,0,13.864-6.22,13.864-13.868L356.348,14.576z"
            data-original="#000000"
            class="active-path"
            data-old_color="#000000"
            :fill="color"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M205.732,151.704c-15.004-14.992-39.396-14.992-54.392,0c-14.996,14.992-14.996,39.392,0.004,54.392    c14.992,14.992,39.392,14.992,54.388,0C220.724,191.104,220.724,166.696,205.732,151.704z"
            data-original="#000000"
            class="active-path"
            data-old_color="#000000"
            :fill="color"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#FFF',
    },
  },
}
</script>
