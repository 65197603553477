<template>
  <div>
    <div class="flex relative flex-row items-center">
      <svg
        class="h-8 w-8 mr-2 flex-none"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
        />
      </svg>

      <div
        id="browserSourceURL"
        class="flex flex-row items-center flex-1 bg-gray-500 p-2 rounded"
        :class="{ 'cursor-pointer': requiresUpgrade }"
        v-on="requiresUpgrade ? { click: browserSourceClick } : { click : copyBrowserSourceURL }"
      >
        <header class="flex flex-1">
          <span v-if="isCopied">Copied!</span>
          <input v-else-if="decodedSourceURL && isSourceURLVisible" :value="decodedSourceURL" type="text" readonly/>
          <span v-else>
          Browser Source URL
          </span>
        </header>
        <div class="flex">
          <button @click="copyBrowserSourceURL">
            <svg
              class="h-6 w-6 mx-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" />
              <path
                d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"
              />
            </svg>
          </button>
          <button @click="displayBrowserSourceURL">
            <svg
              class="h-6 w-6 mx-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path
                fill-rule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div v-if="heartRate && socketConnected" class="flex my-4">
      <button @click="modal = 'activity'" class="text-2xl bg-green-700 p-2 rounded-r-none rounded w-full">
        <svg
          class="h-8 w-8 float-left mr-4 flex-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z"
          />
        </svg>
        <span class="float-left">Ready</span>
        <span class="float-left font-bold text-pink-300 w-28 text-right"
          >{{ heartRate }} BPM</span
        >
      </button>
        <select :style="selectedStyling"  v-if="styles" v-model="selectedStyleID" class="flex-grow-0 w-48 pl-4 rounded rounded-l-none">
          <option v-for="style in styles" :key="style.id" :value="style.id">{{ style.name || style.summary.fontFamily }}
          </option>
        </select>
    </div>
    <div v-else-if="readyWorkoutCode" class="flex my-4">
      <div class="text-2xl bg-green-700 p-2 rounded flex w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8 float-left mr-4 flex-none"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
          />
        </svg>
        <span>Waiting or enter...</span>
        <span class="font-bold mx-4 text-pink-300">{{ readyWorkoutCode }}</span>
        <div class="bg-green-600 h-full w-48 rounded relative">
          <div
            class="bg-white h-full w-1/4 rounded"
            :style="{ width: (30000.0 - elapsedMs) / 300.0 + '%' }"
          />
          <div class="absolute top-1 left-4 text-base text-gray-300">
            {{ Math.round(30 - elapsedMs / 1000.0) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex my-4">
      <div @click="moreStreamsClick" class="text-2xl bg-green-700 p-2 rounded flex w-full cursor-pointer">
        <span>Upgrade For More Streams</span>
      </div>
    </div>


    <div v-if="isNew" class="flex my-4">
      <button class="text-sm">
        <svg
          class="h-6 w-6 float-left mr-4 flex-none"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
        <div class="flex">Download the app for your Apple Watch</div>
      </button>
    </div>
    <div v-else-if="hasLinkedDevice" class="flex my-4">
      <button class="text-sm">
        <svg
          class="h-6 w-6 float-left mr-4 flex-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
            clip-rule="evenodd"
          />
        </svg>
        <div class="flex">Link your Apple Watch for auto-starting</div>
      </button>
    </div>

    <div class="flex my-4">
      <router-link
        to="/home/profile"
        class="text-2xl bg-green-700 p-2 rounded pr-4 w-full flex-row flex"
      >
        <svg
          class="h-8 w-8 float-left mr-4 flex-none"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        <span>Settings</span>
      </router-link>
    </div>
    <report v-if="profile" :profile="profile"></report>

    <div v-if="modal == 'productsUpgrade'">
      <div class="fixed h-screen w-screen inset-0">
        <div
          class="bg-black h-full w-full opacity-70 absolute"
          @click="modal = null"
        ></div>
        <div class="flex w-full h-full justify-center content-center">
          <plans  @done="modal = null; plansMessage = null"  :message="plansMessage" :products="products"></plans>
        </div>
      </div>
    </div>
    <div v-else-if="modal == 'activity'">
            <div class="fixed h-screen w-screen inset-0">
        <div
          class="bg-black h-full w-full opacity-70 absolute"
          @click="modal = null"
        ></div>
        <div class="flex w-full h-full justify-center content-center">
          <activity :styleID="selectedStyleID" :initial="{ heartRate }"></activity>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

import { mapState } from 'vuex'
import Plans from '@/components/Plans'
import Activity from '@/components/Activity.vue'
import Report from '@/components/Report.vue'
// import Workout from '@/components/Workout'
// import Activity from '@/components/Activity'
// import Configure from '@/components/Configure'
// import PlayIcon from '@/components/PlayIcon.vue'
// import ConfigureIcon from '@/components/ConfigureIcon.vue'
export default {
  components: {
    // Workout,
    // Activity,
    // Configure,
    // ConfigureIcon,
    // PlayIcon
    Plans,
    Activity,
    Report,
  },
  middleware: 'auth',
  data() {
    return {
      today: new Date(),
      email: '',
      shortId: '',
      token: null,
      configuring: false,
      activityType: 'initial',
      isWaiting: false,
      timerID: null,
      isConnecting: false,
      profile: null,
      lastCall: null,
      updated: null,
      publishableKey: process.env.VUE_APP_PUBLISHABLE_KEY,
      modal: null,
      billingMonthly: false,
      products: null,
      styles: null,
      selectedStyleID: null,
      isSourceURLVisible: false,
      isCopied: null,
      plansMessage: null
    }
  },
  computed: {
    ...mapState({
      socketConnected: (state) => state.socket.isConnected,
      stateHeartRate: (state) => state.workoutData.heartRate,
      stateActiveEnergyBurned: (state) => state.workoutData.activeEnergyBurned,
    }),
    decodedSourceURL() {
      const profile = this.profile

      if (!profile) {
        return
      }

      const encodedSourceURL = profile.sourceURL

      if (!encodedSourceURL) {
        return 
      }

      return decodeURIComponent(encodedSourceURL)
    },
    billingAnnually : {
      get: function () {
        return !this.billingMonthly
      },
      set: function (newVal) {
        this.billingMonthly = !newVal
      },
    },
    requiresUpgrade() {
      if (!this.profile) {
        return true
      }
      const plan = this.profile.plan
      return plan == 'starter'
    },
    heartRate() {
      const heartRateDecimal = this.stateHeartRate
      if (!heartRateDecimal) {
        return
      }
      return Math.round(heartRateDecimal)
    },
    readyWorkoutCode() {
      if (!this.profile) {
        return
      }
      const readyWorkoutID = this.profile.readyWorkoutID
      if (readyWorkoutID) {
        return readyWorkoutID.substring(0, 5)
      }
      return null
    },
    elapsedMs() {
      if (!this.updated || !this.lastCall) {
        return 0.0
      }
      return this.updated - this.lastCall
    },
    isNew() {
      const profile = this.profile
      if (!profile) {
        return 0
      }
      return profile.isNew && this.workoutCount == 0
    },
    workoutCount() {
      const profile = this.profile
      if (!profile) {
        return 0
      }
      const workouts = profile.workouts
      if (!workouts) {
        return 0
      }
      return workouts.length || 0
    },
    hasLinkedDevice() {
      const profile = this.profile
      if (!profile) {
        return false
      }
      const devices = profile.devices
      if (!devices) {
        return false
      }
      if (!devices.some) {
        return false
      }
      return devices.some((device) => device.isRegistered)
    },
    fullId() {
      const profile = this.profile
      if (!profile) {
        return
      }
      return profile.activeWorkoutID
    },
    monthName() {
      return monthNames[this.today.getMonth()]
    },
    fullYear() {
      return this.today.getFullYear()
    },
    workoutReport() {
      const profile = this.profile

      const plan = (profile && profile.plan) || 'starter'

      const workouts = (profile && profile.workouts) || []

      const monthCount = this.workoutCount
      const monthMS = workouts
        .map(
          (workout) => new Date(workout.updatedAt) - new Date(workout.createdAt)
        )
        .reduce((total, current) => total + current, 0)
      const maxMS =
        plan == 'unlimited'
          ? Number.POSITIVE_INFINITY
          : 5.0 * 60.0 * 60.0 * 1000.0
      const maxCount = plan == 'unlimited' ? Number.POSITIVE_INFINITY : 10

      return {
        month: {
          count: monthCount,
          ms: monthMS,
        },
        max: {
          count: maxCount,
          ms: maxMS,
        },
      }
    },
    selectedStyling() {
      const styleID = this.selectedStyleID

      if (!styleID || !(this.styles)) {
        return {}
      }

      const styleSummary = this.styles.find( style => style.id == styleID)

      if (!styleSummary) {
        return {}
      }

      return {
        backgroundColor : this.hexcodeFromColorValue(styleSummary.summary.secondaryColor),
        color : this.hexcodeFromColorValue(styleSummary.summary.primaryColor)
      }

    }
  },
  watch: {
    fullId(newId, oldId) {
      if (oldId !== newId && this.socketConnected) {
        this.$disconnect()
      }
      if (this.fullId) {
        this.startActivity()
      }
    },
    updated() {
      if (!this.updated || !this.lastCall) {
        return
      }
      const difference = this.updated - this.lastCall
      if (difference > 28500) {
        this.loadProfile()
      }
    },
  },
  mounted() {
    this.$store.commit('initialize')
    this.email = this.$store.getters.email
    this.loadProducts()
    this.loadProfile()
    this.loadStyles()
  },
  beforeUnmount() {
    const timerID = this.timerID
    if (timerID) {
      clearInterval(timerID)
      this.timerID = null
    }
  },
  methods: {
    displayBrowserSourceURL() {
      if (this.requiresUpgrade) {
        browserSourceClick()
        return
      }
      this.isSourceURLVisible = !(this.isSourceURLVisible)
    },
    copyBrowserSourceURL() {
      if (this.requiresUpgrade) {
        browserSourceClick()
        return
      }
      this.isSourceURLVisible = true
      setTimeout(this.beginCopy, 1)
    },
    beginCopy() {
        const inputElem = document.querySelector("#browserSourceURL > header > input")
        inputElem.select()
        document.execCommand("copy")
        this.isSourceURLVisible = false
        this.isCopied = true
      setTimeout(this.endCopy, 5000)

    },
    endCopy() {
      this.isCopied = false
    },
    hexcodeFromColorValue(colorValue) {
      return '#' + colorValue.toString(16).padStart(8, '0').toUpperCase()
    },
    loadStyles() {
      if (this.stateStyling) {
        const simple = {
          textColor: integer(this.stateStyling.color),
          backgroundColor: integer(this.stateStyling.backgroundColor),
          fontFamily: this.stateStyling.fontFamily,
        }
        const name = ''
        this.axios
          .post(
            '/api/v1/styles',
            {
              name,
              data: {
                simple,
              },
            },
            {
              headers: {
                Authorization: 'Bearer ' + this.$store.getters.token,
              },
            }
          )
          .then(() => {
            this.$store.commit('clearStyling')
            this.loadStyles()
          })
        return
      }
      this.axios
        .get('/api/v1/styles', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
          },
        })
        .then((response) => {
          const styles = response.data
          if (!styles) {
            return
          }
          this.styles = styles
        })
    },
    loadProducts() {
      this.axios.get('/api/v1/products').then((response) => {
        const products = response.data
        if (!products) {
          return
        }
        this.products = products
      })
    },
    loadProfile() {
      this.axios
        .get('/api/v1/users', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
          },
        })
        .then((response) => {
          const profile = response.data
          if (!profile) {
            return
          }
          this.profile = profile
          this.lastCall = new Date()
          if (!this.timerID) {
            this.timerID = setInterval(this.invalidate, 1000)
          }
        })
    },
    invalidate() {
      this.updated = new Date()
      this.refreshActiveWorkout()
    },
    updatedAtFormat(workout) {
      return new Date(workout.updatedAt).toLocaleDateString()
    },
    timeLengthFormat(workout) {
      const totalMSeconds =
        new Date(workout.updatedAt) - new Date(workout.createdAt)
      const totalSeconds = totalMSeconds / 1000.0
      const totalMinutes = totalSeconds / 60.0
      const hours = Math.floor(totalMinutes / 60.0)
      const minutes = Math.round(totalMinutes - hours * 60.0)
      var strings = Array()
      if (hours > 0) {
        strings.push(hours)
        strings.push('h')
      }
      strings.push(minutes)
      strings.push('min')
      return strings.join(' ')
    },
    timeFormat(totalMSeconds) {
      const totalSeconds = totalMSeconds / 1000.0
      const totalMinutes = totalSeconds / 60.0
      const hours = Math.floor(totalMinutes / 60.0)
      const minutes = Math.round(totalMinutes - hours * 60.0)
      var strings = Array()
      if (hours > 0) {
        strings.push(hours)
        strings.push('h')
      }
      strings.push(minutes)
      strings.push('min')
      return strings.join(' ')
    },
    isStreaming(workout) {
      return (
        new Date() - new Date(workout.updatedAt) < 30000 &&
        this.socketConnected &&
        this.heartRate
      )
    },
    startActivity() {
      const host = process.env.VUE_APP_WEBSOCKET_HOST || window.location.host
      const scheme = window.location.protocol === 'https:' ? 'wss' : 'ws'
      const baseUrl = `${scheme}://${host}`
      this.$connect(baseUrl + '/api/v1/workouts/' + this.fullId + '/listen')
      this.isConnecting = true
    },
    refreshActiveWorkout() {
      if (this.socketConnected && this.heartRate) {
        return
      }
      this.axios
        .get('/api/v1/workouts/active', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
          },
          validateStatus(status) {
            return (
              (status >= 200 && status < 300) ||
              status === 404 ||
              status === 403
            )
          },
        })
        .then((response) => {
          if (
            response.status / 100 === 2 &&
            response.data &&
            response.data.id
          ) {
            this.profile.activeWorkoutID = response.data.id
          }
        })
    },
    moreStreamsClick() {
      this.plansMessage = 'Upgrade to Premium and Get Unlimited Streams'
      this.modal = 'productsUpgrade'
    },
    browserSourceClick() {
      this.plansMessage = 'Upgrade to Premium and get a static browser URL'
      this.modal = 'productsUpgrade'
    },
    showConfigure() {
      this.configuring = true
    },
    showFullscreen() {
      if (this.activityType === 'full') {
        this.activityType = 'initial'
      } else {
        this.activityType = 'full'
      }
    },
    cancel() {
      this.$disconnect()
      this.shortId = ''
      this.$refs.workout.cancel()
      this.$store.commit('endWorkoutData')
      this.fullId = null
      this.isConnecting = false
    },
    startWorkout() {
      this.$refs.workout.start()
    },
    logout() {
      this.$store.commit('clear')
      this.$router.push({ path: '/' })
    },
  },
}
</script>
<style scoped>

#browserSourceURL > header > input {
  @apply bg-transparent w-full text-sm font-bold;
}
</style>
