<template>
  <div
    id="about"
    class="container mx-auto"
  >
    <main class="mb-5 p-5">
      <h1>About Heartwitch</h1>
      <img
        class="h-48"
        src="/img/logo.svg"
      >
      <h2>
        <a
          href="/downloads/heartwitch-media-assets.zip"
        >Download Guide and Assets</a>
      </h2>
      <h2 id="faq">
        FAQ
      </h2>
      <ul>
        <li>
          <h3>What is this exactly?</h3>
          <p>
            <strong>Heartwitch</strong> is a
            <strong>combination Apple Watch and Web Application</strong> for
            sending your health data to a web browser window.
          </p>
        </li>
        <li>
          <h3>
            Why would you want to send your health data to a web browser window?
          </h3>

          <p>
            In the last few live years, live streams have become incredably
            popular and in many tense circumstances streamers want to share
            their health stats specifically their heart rate. For instance,
            speedrunners who wish to break records as to how quickly they can
            beat a game. With Heartwitch,
            <strong>there is no need for extra hardware</strong>. It simply uses
            the sensors on the Apple Watch to detect your heart rate and it
            sends it through our servers directly to your browsers.
          </p><figure>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/TJnk7a-Lefo"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
            <figcaption>
              Speedrunning Super Mario Bros. with Heart Rate Monitor
            </figcaption>
          </figure>
          <figure>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/rLDYq62IW4Y?start=17"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
            <figcaption>
              Sharing Heart Rate with Heartwitch while Playing Mario Kart 8
              Deluxe
            </figcaption>
          </figure>
        </li>
        <li>
          <h3>Do you save my health info?</h3>
          <p>
            <strong>None of your health data is saved on our server.</strong>
            Every health stat is received by our servers from your Apple Watch
            and directly sent to the web browser. At no point is it saved on our
            end. Each workout is saved locally on your watch and stored on your
            devices along with your other workouts stored on your device but
            none of the health data is stored by us.
          </p>
        </li>
        <li>
          <h3>How do I launch the app? I don't see an icon on my iPhone?</h3>
          <p>
            <strong>Heartwitch is a completely independent watch app.</strong>
            Therefore there is no app for the iPhone and at this point there is
            no plan for one.
          </p>
        </li>
        <li>
          <h3>How can I share my web browser window on a livestream?</h3>
          <p>
            If you are using OBS to stream online, share your web browser window
            using the
            <a
              href="https://obsproject.com/wiki/Sources-Guide#window-capture"
            >Window Capture Source</a>.
          </p>
        </li>
        <li>
          <h3>What makes this app so special?</h3>
          <p>
            There's no need for additional hardware and using the Apple Watch
            health data is updated frequently.
          </p>
        </li>
      </ul>
      <h2 id="how-to-get-started">
        How To Get Started
      </h2>
      <figure>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/R1oQ6-sXfNY"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </figure>
      <ol class="list-decimal list-inside">
        <li>
          <strong>Sign up on the website</strong> with the email address of your
          Apple ID.
        </li>
        <li>Within 24 hours <strong>receive a TestFlight invite.</strong></li>
        <li>
          <strong>Install the Independent Watch App</strong> for Heartwitch
        </li>
        <li><strong>Login</strong> to the website</li>
        <li><strong>Launch</strong> the Apple Watch App</li>
        <li>
          Choose Activity Type and
          <strong>enter Workout Code from the web browser into the watch</strong>
        </li>
        <li>
          <strong>Web Browser will automatically display your heart rate</strong>
          right from your Apple Watch
        </li>
      </ol>
      <h2 id="contact-info">
        Contact Info
      </h2>
      <section>
        <h3>Leo Dion</h3>
        <a href="mailto:leo@heartwitch.app">leo@heartwitch.app</a><br>
        <a href="https://twitter.com/leogdion">@leogdion</a>
      </section>
      <h2 id="videos-optional-youtube-link-for-in-use-or-gif-for-screenshots-">
        Resources
      </h2>
      <h3>
        <a
          href="https://www.youtube.com/watch?v=R1oQ6-sXfNY&list=PLmpJxPaZbSnCCcCWUs0r7jFXFL4tggZ5n"
          target="_blank"
        >Videos - YouTube Playlist</a>
      </h3>
      <h3>Screenshots</h3>
      <h4>Live Stream</h4>
      <ul class="live-stream-screenshots">
        <li>
          <a href="/img/screenshots/livestream/Heartwitch-BOTW-HCG.png">
            <figure>
              <img src="/img/screenshots/livestream/Heartwitch-BOTW-HCG.png">
              <figcaption>
                Hyrule Castle - Breath of the Wild for the Nintendo Switch
              </figcaption>
            </figure>
          </a>
        </li>
        <li>
          <a href="/img/screenshots/livestream/Heartwitch-SMK8D-DKJ.png">
            <figure>
              <img src="/img/screenshots/livestream/Heartwitch-SMK8D-DKJ.png">
              <figcaption>
                Donkey Kong Jungle - Mario Kart 8 Deluxe for the Nintendo Switch
              </figcaption>
            </figure>
          </a>
        </li>

        <li>
          <a href="/img/screenshots/livestream/Heartwitch-SMK8D-RR.png">
            <figure>
              <img src="/img/screenshots/livestream/Heartwitch-SMK8D-RR.png">
              <figcaption>
                Rainbow Road - Mario Kart 8 Deluxe for the Nintendo Switch
              </figcaption>
            </figure>
          </a>
        </li>

        <li>
          <a href="/img/screenshots/livestream/Heartwitch-BOTW-SMG.png">
            <figure>
              <img src="/img/screenshots/livestream/Heartwitch-BOTW-SMG.png">
              <figcaption>
                Shrine Major Test - Breath of the Wild for the Nintendo Switch
              </figcaption>
            </figure>
          </a>
        </li>

        <li>
          <a href="/img/screenshots/livestream/Heartwitch-SMK8D-MC.png">
            <figure>
              <img src="/img/screenshots/livestream/Heartwitch-SMK8D-MC.png">
              <figcaption>
                Mario Circuit - Mario Kart 8 Deluxe for the Nintendo Switch
              </figcaption>
            </figure>
          </a>
        </li>
      </ul>
      <h4>Apple Watch</h4>
      <ul class="watch-screenshots">
        <li>
          <a href="/img/screenshots/watch/001.StartWorkout.PNG">
            <figure>
              <img src="/img/screenshots/watch/001.StartWorkout.PNG">
              <figcaption>Starting a Workout</figcaption>
            </figure>
          </a>
        </li>

        <li>
          <a href="/img/screenshots/watch/002.ActiveWorkout.PNG">
            <figure>
              <img src="/img/screenshots/watch/002.ActiveWorkout.PNG">
              <figcaption>An Active Workout</figcaption>
            </figure>
          </a>
        </li>
      </ul>
      <h4>Web</h4>
      <ul class="web-screenshots">
        <li>
          <a href="/img/screenshots/web/001.LoginScreen.png">
            <figure>
              <img src="/img/screenshots/web/001.LoginScreen.png">
              <figcaption>Login Screen</figcaption>
            </figure>
          </a>
        </li>
        <li>
          <a href="/img/screenshots/web/002.CodeScreen.png">
            <figure>
              <img src="/img/screenshots/web/002.CodeScreen.png">
              <figcaption>Getting a Code</figcaption>
            </figure>
          </a>
        </li>
        <li>
          <a href="/img/screenshots/web/003.ActiveScreen.png">
            <figure>
              <img src="/img/screenshots/web/003.ActiveScreen.png">
              <figcaption>Active Screen</figcaption>
            </figure>
          </a>
        </li>
      </ul>
    </main>
  </div>
</template>
