<template>
  <div>
    <form id="account" class="w-auto text-left py-4">
      <div class="mb-4">
        <label class="block text-sm mb-2 text-gray-500 font-light" for="email">
          email address
        </label>
        <input
          id="email"
          v-model="email"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="email"
          placeholder="Email Address"
        />
      </div>
      <div class="mb-6">
        <label
          class="block text-sm mb-2 text-gray-500 font-light"
          for="password"
        >
          password
        </label>
        <input
          id="password"
          v-model="password"
          class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          type="password"
          autocomplete="password"
          placeholder="******************"
        />
        <p class="text-red-500 text-xs italic invisible">
          Please choose a password.
        </p>
      </div>
      <div class="flex items-center justify-between">
        <button
          id="sign-up"
          class="bg-red-500 hover:bg-red-100 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:text-red-700 disabled:cursor-not-allowed"
          type="button"
          @click="signup"
        >
          Sign Up
        </button>
        <button
          id="login"
          class="inline-block align-baseline font-bold text-sm text-red-500 hover:text-red-300"
          type="button"
          @click="login"
        >
          Login
        </button>
      </div>
    </form>
    <div v-if="modal" id="modal">
      <main>
        <header>
          {{ modal.title }}
        </header>
        <main>
          {{ modal.message }}
        </main>
        <footer class="flex flex-row justify-between">
          <button v-if="modal.reset" @click="resetPassword" > Reset Password </button>
          <button @click="modal = nil">OK</button>
        </footer>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  metaInfo: {},
  data() {
    return {
      modal: null,
      password: '',
      email: '',
    }
  },
  computed: {
    resetHref() {
      return (
        'mailto:reset-password@heartwitch.app?cc=' +
        this.email +
        '&?subject=Please%20Reset%20My%20Password'
      )
    },
  },
  methods: {
    errorFrom(error, source) {
      const response = error.response
      if (!response) {
        throw error
      }
      const status = response.status
      const data = response.data
      const reason = (data && data.reason)
      if (status === 401) {
        if (source == "signup") {

                  return {
            title: 'Email Address Already In Use',
            message:
              'Sorry this email address is already signed up. Try logging in with your correct password or request a password reset.',
              reset: true
          }
        } else {
                  return {
            title: 'Incorrect Password',
            message:
              'Try logging in with your correct password or request a password reset.',
              reset: true
          }

        }
      } else if (status === 409) {
        this.login("signup")
      } else if (reason === "email is not a valid email address") {
          return {
            title: 'Invalid Email Address',
            message:
              'This is not a valid email address. Please try again',
          }

      } 
        throw error
      // if (error.response && error.response.data) {
      //   if (
      //     error.response.data.reason ===
      //     'duplicate key value violates unique constraint "uq:User.email"'
      //   ) {
      //     return {
      //       title: 'Email address has already signed up',
      //       message:
      //         'You may have already signed up. Go ahead and try logging in or request a password reset.',
      //     }
      //   } else if (
      //     error.response.data.reason === 'Unauthorized'
      //   ) {
      //     return {
      //       title: 'Incorrect Password',
      //       message:
      //         'Try logging in with your correct password or request a password reset.',
      //     }
      //   }
      // }
    },
    signup() {
      const { email, password } = this
      this.axios
        .post('/api/v1/users', {
          email,
          password,
        })
        .then(() => {
          this.loginWith(email, password)
        })
        .catch((error) => {
          this.modal = this.errorFrom(error)
        })
    },
    loginWith(email, password, source) {
      this.axios
        .post(
          '/api/v1/login',
          {},
          {
            auth: {
              username: email,
              password,
            },
          }
        )
        .then((info) => {
          this.$store.commit('update', {
            response: info.data,
            email,
          })
          this.$router.push({ path: '/home' })
        })
        .catch((error) => {
          this.modal = this.errorFrom(error, source)
        })
    },
    login(source) {
      const { email, password } = this
      this.loginWith(email, password, source)
    },
    resetPassword() {
      const { email } = this
      this.axios
        .post('/api/v1/users/reset', {
          email,
        })
        .then(() => {
           this.modal = {
            title: 'Request Sent.',
            message:
              'You should receive an email shortly with instructions on how to change your password.',
          }
        })

    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#account :disabled {
  opacity: 0.5;
}
#modal {
  @apply fixed inset-0 w-full h-full flex flex-col items-center justify-center;
  background-color: rgba(0, 0, 0, 0.5);
}
#modal > main {
  @apply bg-white p-2 rounded;
  width: 32rem;
}
#modal > main > header {
  @apply text-black p-4;
}
#modal > main > main {
  @apply text-black p-4 text-xl;
}
#modal > main > footer {
  @apply p-4;
}
#modal > main > footer > button,
#modal > main > footer > a {
  @apply bg-heart-deep-red p-2 px-4  rounded;
}
</style>
