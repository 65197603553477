<template>
          <activity class="fixed inset-0" v-if="heartRate && styleID" :styleID="styleID" :sourceID="sourceHashString" :initial="{ heartRate }"></activity>
<div class="inset-0 fixed bg-black flex justify-center items-center" v-else>
  <div>
  Waiting for Connection...
</div>
</div>
  
</template>
<script>
import { mapState } from 'vuex'
import Activity from '@/components/Activity.vue'
export default {
    components: {
Activity
    },
  data() {
      return {
    sourceHashString: null,
    timerID: null,
    workoutID: null,
    styleID: null,
    lastUpdated: null
      }
  },
  computed: {
    ...mapState({
      socketConnected: (state) => state.socket.isConnected,
      stateHeartRate: (state) => state.workoutData.heartRate,
      stateActiveEnergyBurned: (state) => state.workoutData.activeEnergyBurned,
    }),
    heartRate() {
      const heartRateDecimal = this.stateHeartRate
      if (!heartRateDecimal) {
        return
      }
      return Math.round(heartRateDecimal)
    },
  },
  watch: {
    workoutID() {
      if (this.workoutID) {
        this.startActivity()
      }
    },
    stateHeartRate() {
      this.lastUpdated = new Date()
    }
  },
  mounted() {
    this.sourceHashString = this.$route.params.id
    this.timerID = setInterval(this.invalidate, 1000)
  },
  methods: {
    startActivity() {
      const host = process.env.VUE_APP_WEBSOCKET_HOST || window.location.host
      const scheme = window.location.protocol === 'https:' ? 'wss' : 'ws'
      const baseUrl = `${scheme}://${host}`
      this.$connect(baseUrl + '/api/v1/workouts/' + this.workoutID + '/listen')
      this.isConnecting = true
      this.lastUpdated = new Date()
    },
    invalidate() {
      const since = this.lastUpdated ? (new Date() - this.lastUpdated) : Infinity
      if (this.socketConnected && this.heartRate && since < 30000) {
        return
      }
      if (!this.sourceHashString) {
        return
      }
      
      this.axios
        .get('/api/v1/sources/' + this.sourceHashString + '/workout', {
          validateStatus(status) {
            return (
              (status >= 200 && status < 300) ||
              status === 404 ||
              status === 403
            )
          },
        })
        .then((response) => {
          if (
            response.status / 100 === 2 &&
            response.data
          ) {
            this.workoutID = response.data.workoutID
            this.styleID = response.data.styleID
          }
        }).catch(() => {
            this.$router.push('/#/home')
        })
    },
  },
}
</script>
