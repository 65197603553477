<template>
  <header class="mt-8 px-5">
    <nav>
      <ul>
        <li>
          <router-link to="/">
            <img class="inline" src="/img/logo.svg" style="height: 1em" />
            Heartwitch
          </router-link>
        </li>
        <li>
          <router-link to="/about"> About </router-link>
        </li>
        <li>
          <router-link to="/privacy-policy"> Privacy Policy </router-link>
        </li>
        <li>
          <router-link to="/about"> Media Kit </router-link>
        </li>
        <li>
          <a
            href="https://apps.apple.com/app/apple-store/id1480031203?pt=2108001&ct=about-top-nav&mt=8"
            target="_blank"
          >
            Download App
          </a>
        </li>
        <li>
          <router-link to="/contact-info"> Contact </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  components: {},
}
</script>

<style>
header > nav > ul > li {
  @apply inline pr-4;
}
</style>
