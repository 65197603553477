<template>
  <div>
    <div class="flex mt-6 text-xs">{{ monthName }} {{ fullYear }}</div>
    <div v-if="profile">
      <div
        class="flex my-4 mb-0 mt-0 p-2 space-x-4 flex-row items-center justify-self-stretch"
      >
        <svg
          class="h-8 w-8 flex-none"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>
        <div class="rounded flex-1 flex-col flex space-y-1 self-stretch">
          <div
            :class="{ 'bg-green-900': requiresUpgrade }"
            class="rounded flex-1"
          >
            <div
              class="bg-green-600 h-full rounded max-w-full"
              :style="{
                width:
                  (workoutReport.month.count / workoutReport.max.count) *
                    100.0 +
                  '%',
              }"
            />
          </div>
          <div
            :class="{ 'bg-green-900': requiresUpgrade }"
            class="rounded flex-1"
          >
            <div
              class="bg-green-600 h-full rounded max-w-full"
              :style="{
                width:
                  (workoutReport.month.ms / workoutReport.max.ms) * 100.0 + '%',
              }"
            />
          </div>
        </div>
        <div class="flex-col flex text-sm text-right">
          <div>{{ workoutReport.month.count }} Streams</div>
          <div>
            {{ timeFormat(workoutReport.month.ms) }}
          </div>
        </div>
      </div>

      <div
        v-for="workout in profile.workouts"
        :key="workout.createdAt"
        class="flex p-2 space-x-4 flex-row items-center justify-self-stretch"
      >
        <svg
          class="h-8 w-8 flex-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"
          />
        </svg>

        <div class="flex-col flex text-sm text-left">
          <div :class="{ 'font-bold': isStreaming(workout) }">
            {{ isStreaming(workout) ? 'Streaming' : 'Streamed' }}
          </div>
          <div class="flex flex-row space-x-4">
            <span>{{ updatedAtFormat(workout) }}</span
            ><span> {{ timeLengthFormat(workout) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export default {
    data() {
return {
      today: new Date(),
    }
    },
  props: ['profile'],
  computed: {

    ...mapState({
      socketConnected: (state) => state.socket.isConnected,
      stateHeartRate: (state) => state.workoutData.heartRate,
      stateActiveEnergyBurned: (state) => state.workoutData.activeEnergyBurned,
    }),
    requiresUpgrade() {
      if (!this.profile) {
        return true
      }
      const plan = this.profile.plan
      return plan == 'starter'
    },

    monthName() {
      return monthNames[this.today.getMonth()]
    },
    fullYear() {
      return this.today.getFullYear()
    },
    workoutCount() {
      const profile = this.profile
      if (!profile) {
        return 0
      }
      const workouts = profile.workouts
      if (!workouts) {
        return 0
      }
      return workouts.length || 0
    },
    workoutReport() {
      const profile = this.profile

      const plan = (profile && profile.plan) || 'starter'

      const workouts = (profile && profile.workouts) || []

      const monthCount = this.workoutCount
      const monthMS = workouts
        .map(
          (workout) => new Date(workout.updatedAt) - new Date(workout.createdAt)
        )
        .reduce((total, current) => total + current, 0)
      const maxMS =
        plan == 'unlimited'
          ? Number.POSITIVE_INFINITY
          : 5.0 * 60.0 * 60.0 * 1000.0
      const maxCount = plan == 'unlimited' ? Number.POSITIVE_INFINITY : 10

      return {
        month: {
          count: monthCount,
          ms: monthMS,
        },
        max: {
          count: maxCount,
          ms: maxMS,
        },
      }
    },
  },
  methods: {
    updatedAtFormat(workout) {
      return new Date(workout.updatedAt).toLocaleDateString()
    },
    timeLengthFormat(workout) {
      const totalMSeconds =
        new Date(workout.updatedAt) - new Date(workout.createdAt)
      const totalSeconds = totalMSeconds / 1000.0
      const totalMinutes = totalSeconds / 60.0
      const hours = Math.floor(totalMinutes / 60.0)
      const minutes = Math.round(totalMinutes - hours * 60.0)
      var strings = Array()
      if (hours > 0) {
        strings.push(hours)
        strings.push('h')
      }
      strings.push(minutes)
      strings.push('min')
      return strings.join(' ')
    },
    timeFormat(totalMSeconds) {
      const totalSeconds = totalMSeconds / 1000.0
      const totalMinutes = totalSeconds / 60.0
      const hours = Math.floor(totalMinutes / 60.0)
      const minutes = Math.round(totalMinutes - hours * 60.0)
      var strings = Array()
      if (hours > 0) {
        strings.push(hours)
        strings.push('h')
      }
      strings.push(minutes)
      strings.push('min')
      return strings.join(' ')
    },
    isStreaming(workout) {
      return (
        new Date() - new Date(workout.updatedAt) < 30000 &&
        (workout.updatedAt != workout.createdAt)
      )
    },
  },
}
</script>
