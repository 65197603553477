<template>
  <main
id="privacy-policy" class="mb-5 p-5"
>
    <h1>Privacy Policy</h1>
    <p>
      This policy applies to all information collected or submitted on
      Heartwitch website and it's corresponding apps.
    </p>
    <h2>Information We Collect</h2>
    <p>Heartwitch collects:</p>
    <ul>
      <li>Anonymous usage data regarding sessions and registration</li>
      <li>Crash reports on the web site and the app</li>
    </ul>
    <p>
      This anonymous usage data is stored by a third party in accordance with
      this privacy policy, and is retained indefinitely. We use the data to help
      make decisions when working on updates to our apps - for example:
    </p>
    <ul>
      <li>We use crash reports to help fix crashes.</li>
      <li>
        We use usage data to inform and verify our decisions in making the app
        easier for users
      </li>
    </ul>
    <p>
      Detailed usage data is only used for internal informational purposes in
      the manner described above.
    </p>
    <h2>Information Handling</h2>
    <p>
      We don’t share any personal information with third parties except if
      necessary to operate our apps or services. For example, if you sign up for
      our mailing list and we use a third party mailing list service, we’ll need
      to give them your email address. We will always exercise extreme caution
      when dealing with your information.
    </p>
    <p>
      We may disclose your information in response to subpoenas, court orders,
      or other legal requirements; to exercise our legal rights or defend
      against legal claims; to investigate, prevent, or take action regarding
      illegal activities, suspected fraud or abuse, violations of our policies;
      or to protect our rights and property.
    </p>
    <p>
      In the future, we may sell to, buy, merge with, or partner with other
      businesses. If such a situation occurs, user information may be among the
      transferred assets.
    </p>
    <h2>HealthKit</h2>
    <p>
      Heartwitch requires integration with Apple's HealthKit to access and
      publisize your certain health statistics such as heart rate. If fitness
      tracking is enabled for an activity this health data will be saved locally
      and in the health store, but will not be stored online in any way or
      shared with any other third-party services.
    </p>
    <h2>Security</h2>
    <p>
      All data submissions (such as forms on our website) are transmitted using
      industry-standard SSL encryption. This can be verified by looking for the
      padlock icon in your browser. The SSL certificate should be issued with a
      common name (CN) of
      <strong>heartwitch.app</strong>.
    </p>
    <h2>Accessing Your Information</h2>
    <p>
      If you are subscribed any mailing list of ours, you will be able to
      unsubscribe at any time by clicking a link at the bottom of each email
      received. To request deletion of your personal data, contact customer
      support at
      <a href="mailto:leo@heartwitch.app">leo@heartwitch.app</a> and we’ll help
      you out. We’ll need any email addresses you used to sign up to mailing
      lists or purchase our products. Please be aware that if you’ve purchased a
      product from us, deleting your information will mean we won’t be able to
      assist with license and registration problems in the future.
    </p>
    <h2>Your Consent</h2>
    <p>By using our site or apps, you consent to our privacy policy.</p>
    <h2>Contacting Us</h2>
    <p>
      If you have questions regarding this privacy policy, please e-mail
      <a href="mailto:leo@heartwitch.app">leo@heartwitch.app</a>.
    </p>
  </main>
</template>
