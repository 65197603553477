<template>
  <div class="style-view">
    <div :style="styleForPreview" class="preview flex">
      <div class="content flex items-center mx-2">
        <logo class="flex-1 flex-shrink-0" style="width: 60px; height: 60px" />
        <div class="flex justify-self-start justify-start">
          <span class="flex-none ml-2 flex-shrink-0">{{ value }}</span
          ><span class="flex-none ml-2 flex-shrink-0">BPM</span>
        </div>
      </div>
    </div>
    <div class="py-4">     
      <div> 
        <input type="text" v-model.trim="currentStyle.name" placeholder="Style Name">  
      </div>
    </div>
    <div v-if="source">
      <input id="use-source" type="checkbox" v-model="useSource"/> 
      <label for="use-source">Use For Source</label>
    </div>
    <div class="h-64 overflow-y-scroll">
      <div class="text-sm">font</div>
      <div
        title="Tap to Change Font"
        :disabled="isSaving" 
        @click="updatingFonts = !updatingFonts"
        class="h-8 py-2 cursor-pointer"
      >
        {{ currentStyle.data.simple.fontFamily }}
        <span :style="styleForFont(currentStyle.data.simple.fontFamily)"
          >120BPM</span
        >
        <svg
          v-if="updatingFonts"
          class="inline h-5 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
        <svg
          v-else
          class="inline h-5 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 15l7-7 7 7"
          />
        </svg>
      </div>
      <div class="ml-2 py-2" v-if="updatingFonts">
        <div v-for="(_, font) in fonts" :key="font">
          <div
            class="font-selector text-gray-500 py-2 pl-2"
            :disabled="isSaving" 
            @click="setFontFamily(font)"
            :class="{ selected: isSelectedFont(font) }"
          >
            {{ font }} <span :style="styleForFont(font)">120BPM</span>
          </div>
        </div>
        <div
            :disabled="isSaving" 
          class="flex items-center space-x-2 cursor-pointer"
          @click="updatingFonts = !updatingFonts"
        >
          <svg
            class="flex w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 15l7-7 7 7"
            />
          </svg>
          <hr class="flex flex-1 border-black" />
        </div>
      </div>
      <div class="text-sm pt-4">background color</div>
      <div
      :disabled="isSaving" 
        @click="updatingBackgroundColors = !updatingBackgroundColors"
        class="py-2 flex"
      >
        <div
          class="flex-grow h-8 border-blue-600 border-4"
          :style="{
            backgroundColor: hexcodeFromColorValue(
              currentStyle.data.simple.backgroundColor
            ),
          }"
        ></div>
        <svg
          v-if="updatingBackgroundColors"
          class="inline h-5 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
        <svg
          v-else
          class="inline h-5 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 15l7-7 7 7"
          />
        </svg>
      </div>
      <div v-if="updatingBackgroundColors" class="py-2">
        <div class="grid grid-flow-col grid-rows-4 colors">
          <div
            v-for="(hex, name) in colors"
            :key="name"
            :style="{ backgroundColor: hex }"
            :disabled="isSaving" 
            @click="setColor({ backgroundColor: hex })"
          />
        </div>
      </div>
      <div class="text-sm pt-4">text color</div>
      <div :disabled="isSaving"  @click="updatingTextColors = !updatingTextColors" class="py-2 flex">
        <div
          class="flex-grow h-8 border-blue-600 border-4"
          :style="{
            backgroundColor: hexcodeFromColorValue(
              currentStyle.data.simple.textColor
            ),
          }"
        ></div>
        <svg
          v-if="updatingTextColors"
          class="inline h-5 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
        <svg
          v-else
          class="inline h-5 ml-2"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 15l7-7 7 7"
          />
        </svg>
      </div>
      <div v-if="updatingTextColors" class="py-2">
        <div class="grid grid-flow-col grid-rows-4 colors">
          <div
            v-for="(hex, name) in colors"
            :key="name"
            :style="{ backgroundColor: hex }"
            @click="setColor({ textColor: hex })"
            :disabled="isSaving" 
          />
        </div>
      </div>
    </div>
    <div class="flex border-t-2 pt-4 justify-between">
        <button :disabled="isSaving" @click="$emit('completedWithChanges')" class="p-2 bg-gray-400 rounded text-white">Cancel</button>
        <button :disabled="isSaving" @click="beginSavingStyle" class="p-2 bg-heart-red rounded text-white">Save</button>
    </div>
  </div>
</template>
<script>
import Logo from '@/components/Logo.vue'
const WebFont = require('webfontloader')
import IntegerFromHexCode from '@/utils/integer'

const colors = {
  black: '#000',
  'controller-button': '#656d78',
  'controller-grey': '#e6e9ed',
  white: '#fff',

  'red-200': '#fed7d7',
  'heart-red': '#ed5565',
  'heart-deep-red': '#da4453',
  'red-solid': '#f00',

  'orange-solid': '#F60',
  'orange-900': '#7b341e',
  'yellow-200': '#fefcbf',
  'yellow-900': '#744210',

  'green-200': '#c6f6d5',
  'green-solid': '#0f0',
  'blue-200': '#bee3f8',
  'blue-solid': '#00F',

  'blue-900': '#2a4365',
  'indigo-200': '#c3dafe',
  'indigo-900': '#3c366b',
  'purple-solid': '#60F',

  'magenta-solid': '#F09',
  'purple-900': '#44337a',
  'pink-200': '#fed7e2',
  'pink-900': '#702459',
}

const fonts = {
  Oxygen: 1.0,
  'Passion One': 1.2,
  'Special Elite': 1.0,
  Lobster: 1.0,
  'Oxygen Mono': 0.8,
  'Press Start 2P': 0.6,
  Iceland: 1.0,
  'Henny Penny': 0.8,
  Orbitron: 0.8,
  'Mountains of Christmas': 1.0,
}

export default {
  components: {
    Logo,
  },
  props: ['style', 'id', 'source'],
  emits: ['completedWithChanges'],
  data() {
    const currentStyle =
      Object.keys(this.style).length > 0
        ? this.style
        : {
            name: '',
            data: {
              simple: {
                fontFamily: 'Oxygen',
                backgroundColor: 0x0000_00ff,
                textColor: 0xffff_ffff,
              },
            },
          }
    return {
      fontsReady: false,
      currentStyle,
      fonts,
      colors,
      updatingFonts: false,
      updatingBackgroundColors: false,
      updatingTextColors: false,
      timer: null,
      value: '000',
      isSaving: false,
      isEditingName: false,
      useSource: currentStyle.sourceHash ? true : false,
    }
  },
  mounted() {
    WebFont.load({
      google: {
        families: Object.keys(this.fonts),
        text: '012345678909.: BPMCAL',
      },
      active() {
        this.fontsReady = true
      },
    })
    let self = this
    this.timer = setInterval(() => {
      self.value = Math.round(Math.random() * 100) + 90
    }, 1000)
  },
  watch: {
    useSource(value) {
      this.currentStyle.sourceHash = value ? this.source.split('/').pop() : null
    }
  },
  computed: {
    styleForPreview() {
      return {
        backgroundColor: this.hexcodeFromColorValue(
          this.currentStyle.data.simple.backgroundColor
        ),
        color: this.hexcodeFromColorValue(
          this.currentStyle.data.simple.textColor
        ),
        fontFamily: this.quote(this.currentStyle.data.simple.fontFamily),
        fontSize:
          42 * this.fonts[this.currentStyle.data.simple.fontFamily] + 'px',
      }
    },
  },
  methods: {
    isSelectedFont(fontFamily) {
      return this.currentStyle.data.simple.fontFamily == fontFamily
    },
    hexcodeFromColorValue(colorValue) {
      return '#' + colorValue.toString(16).padStart(8, '0').toUpperCase()
    },
    styleForFont(fontFamilyName) {
      return {
        fontFamily: fontFamilyName,
      }
    },
    setFontFamily(fontFamily) {
      this.currentStyle.data.simple.fontFamily = fontFamily
    },
    quote(value) {
      if (value.includes(' ')) {
        return '"' + value + '"'
      } else {
        return value
      }
    },
    setColor(data) {
      for (name in this.currentStyle.data.simple) {
        const hexcode = data[name]
        if (!hexcode) {
          continue
        }
        const value = IntegerFromHexCode(hexcode)
        this.currentStyle.data.simple[name] = value
        return
      }
      //IntegerFromHexCode
    },
    beginSavingStyle() {
      const baseUrl = '/api/v1/styles'
      const method = this.id ? 'PUT' : 'POST'
      const urlSubpath = this.id ? ('/' + this.id) : ''
      const url = baseUrl + urlSubpath
      this.isSaving = true
      console.log(this.currentStyle)
      this.axios({
        method,
        url,
        data: this.currentStyle,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.token,
        },
      }).then(() => {
        this.isSaving = false
        this.$emit('completedWithChanges', true)
      })

    }
  },
}
</script>
<style scoped>
.style-view {
  @apply bg-white z-50 m-auto rounded p-4 text-black flex flex-col;
}
.preview {
  @apply h-36 w-64;
}
.font-selector {
  @apply cursor-pointer;
}
.font-selector:hover {
  @apply bg-gray-800 text-white;
}
.font-selector.selected:hover {
  @apply bg-black text-white;
}
.font-selector.selected {
  @apply cursor-pointer text-black;
}
.colors.grid > div {
  @apply w-10 h-10 bg-white cursor-pointer;
}
</style>