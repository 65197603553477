import { createStore } from 'vuex'

export default createStore({
  state: {
    current: {
      string: null,
      expiresAt: null,
      userID: null,
      email: null,
    },
    version: process.env.VERSION,
    workoutData: {
      heartRate: null,
      activeEnergyBurned: null,
    },
    socket: {
      isConnected: false,
    },
  },
  mutations: {
    initialize(state) {
      if (localStorage.getItem('current-data')) {
        const current = JSON.parse(localStorage.getItem('current-data'))
        const expiresAt = new Date(current.expiresAt)
        const now = new Date()
        if (now < expiresAt) {
          state.current = current
        }
      }
      if (localStorage.getItem('styling')) {
        state.styling = JSON.parse(localStorage.getItem('styling'))
      }
      state.workoutData = {
        heartRate: null,
        activeEnergyBurned: null,
      }
      state.socket = {
        isConnected: false,
      }
    },
    update(state, payload) {
      const response = payload.response
      response.email = payload.email
      localStorage.setItem('current-data', JSON.stringify(response))
      state.current.string = payload.response.string
      state.current.expiresAt = payload.response.expiresAt
      state.current.userID = payload.response.userID
      state.current.email = payload.email
    },

    clear(state) {
      localStorage.clear()
      state.current = {
        string: null,
        expiresAt: null,
        userID: null,
        email: null,
      }
    },
    endWorkoutData(state) {
      state.workoutData = {
        heartRate: null,
        activeEnergyBurned: null,
      }
    },
    updateWorkout(state, data) {
      if (data.heartRate) {
        state.workoutData.heartRate = data.heartRate
      }
      state.workoutData.activeEnergyBurned =
        data.activeEnergyBurned | state.workoutData.activeEnergyBurned
    },
    updateStyling(state, styling) {
      state.styling.color = styling.color || state.styling.color
      state.styling.backgroundColor =
        styling.backgroundColor || state.styling.backgroundColor
      state.styling.fontFamily = styling.fontFamily || state.styling.fontFamily
      localStorage.setItem('styling', JSON.stringify(state.styling))
    },
    clearStyling(state) {
      state.styling = undefined
      localStorage.removeItem('styling')
    },
    SOCKET_ONOPEN(state) {
      state.socket.isConnected = true
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR() {},
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      state.workoutData = message
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT() {},
    SOCKET_RECONNECT_ERROR() {},
  },
  actions: {},
  modules: {},
  getters: {
    authenticated: (state) => {
      return state.current.userID !== null && state.current.email !== null
    },
    email: (state) => {
      return state.current.email
    },
    token: (state) => {
      return state.current.string
    },
  },
})
