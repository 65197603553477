function integerFromStyleColor(color) {
  if (typeof color !== 'string') {
    throw TypeError('Invalid String Type')
  }
  if (color[0] !== '#') {
    throw SyntaxError('Invalid String Format')
  }
  let hexcode = color.slice(1)
  if (hexcode.length === 3) {
    hexcode = hexcode + 'F'
  }
  if (hexcode.length === 4) {
    hexcode = hexcode
      .split('')
      .map((char) => char + char)
      .join('')
  }
  if (hexcode.length === 6) {
    hexcode = hexcode + 'FF'
  }
  return parseInt(hexcode, 16)
}

export { integerFromStyleColor as default }
