<template>
  <div class="container mx-auto h-full flex justify-center items-center mt-4">
    <div class="flex-initial">
      <navigation />
      <router-view />
      <copyright />
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import Copyright from '@/components/Footer.vue'

export default {
  components: {
    Navigation,
    Copyright,
  },
}
</script>
<style>
html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  @apply bg-black text-gray-200;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}
</style>
