<template>
    <div>
    <form id="account" class="w-auto text-left py-4">
      <div class="mb-4">
        <label class="block text-sm mb-2 text-gray-500 font-light" for="email">
          email address
        </label>
        <input
          id="email"
          v-model="email"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="email"
          autocomplete="email"
          placeholder="Email Address"
          readonly
        />
      </div>
      <div class="mb-4">
        <label
          class="block text-sm mb-2 text-gray-500 font-light"
          for="password"
        >
          password
        </label>
        <input
          id="password"
          v-model="password"
          class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          type="password"
          autocomplete="new-password"
          placeholder="******************"
          :disabled="invalidated"
        />
        <p class="text-red-500 text-xs italic invisible hidden">
          Please choose a password.
        </p>
      </div>
      <div class="mb-4">
        <label
          class="block text-sm mb-2 text-gray-500 font-light"
          for="confirmPassword"
        >
          confirm password
        </label>
        <input
          id="confirmPassword"
          v-model="confirmPassword"
          class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          type="password"
          autocomplete="new-password"
          placeholder="******************"
          :disabled="invalidated"
        />
        <p class="text-red-500 text-xs italic invisible hidden">
          Please choose a password.
        </p>
      </div>
      <div class="mb-6">
        <p class="text-sm font-bold">
          Password must be at least 8 characters.
        </p>
      </div>
      <div v-if="!invalidated" class="flex items-center justify-between">
        <button
          id="change-password"
          class="bg-red-500 hover:bg-red-100 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:text-red-700 disabled:cursor-not-allowed"
          type="button"
          @click="changePassword"

          :disabled="!canResetPassword"
        >
          Change Password
        </button>
      </div>
    </form>
    <div v-if="modal" id="modal">
      <main>
        <header>
          {{ modal.title }}
        </header>
        <main>
          {{ modal.message }}
        </main>
        <footer class="flex flex-row justify-between">
            <router-link to="/"> OK</router-link>
        </footer>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      email : "",
      password: "",
      confirmPassword: "",
      token: "",
      modal : null,
      invalidated: true
    }
  },
  mounted () {
    const code = this.$route.params.code
    const codeString = atob(code).split(":", 2)
    
    this.token = codeString.pop()
    this.email = codeString.pop()
    const { email, token } = this
      this.axios
        .put('/api/v1/users/reset', {
          email,
          token
        })
        .then(() => {
           this.invalidated = false
        })
        .catch((error) => {
          if (error.response.status === 404) {
              this.modal = {
                  title : "Invalid Reset Password Request",
                  message: "Sorry but it looks like this reset password is no longer valid."
              }
          }
        })
  },
  computed: {
    canResetPassword() {
      return !this.invalidated && this.password === this.confirmPassword && (this.password.length + this.confirmPassword.length >= 16)
    }
  },
  methods: {
      changePassword() {
          console.log("calling")
          if (!this.canResetPassword) {
              return
          }
    const { email, token, password } = this
      this.axios
        .patch('/api/v1/users/reset', {
          email,
          token,
          password
        })
        .then(() => {
          
              this.modal = {
                  title : "Password Change Success",
                  message: "Your password was successfully changed. Go ahead and login with your new password."
              }
        })

      }
  }
}
</script>
<style scoped>
button#change-password {
  @apply disabled:opacity-50 disabled:bg-red-500 disabled:text-white disabled:cursor-not-allowed;
}
#modal {
  @apply fixed inset-0 w-full h-full flex flex-col items-center justify-center;
  background-color: rgba(0, 0, 0, 0.5);
}
#modal > main {
  @apply bg-white p-2 rounded;
  width: 32rem;
}
#modal > main > header {
  @apply text-black p-4;
}
#modal > main > main {
  @apply text-black p-4 text-xl;
}
#modal > main > footer {
  @apply p-4;
}
#modal > main > footer > button,
#modal > main > footer > a {
  @apply bg-heart-deep-red p-2 px-4  rounded;
}
</style>