<template>
  <div class="productsUpgrade">
    <div class="flex flex-row justify-center text-gray-600">
      <h2>Plans and Pricing</h2>
    </div>
    <div class="flex flex-row text-center justify-center m-2 font-black" v-if="message">
      <p>{{ message }}</p>
    </div>
    <div class="toggle-container">
      <div class="px-4 billing" :class="{ active: billingMonthly }">
        Billed Monthly
      </div>
      <div
        class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
      >
        <input
          type="checkbox"
          name="toggle"
          id="toggle"
          class="toggle-checkbox focus:outline-none absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
          v-model="billingAnnually"
        />
        <label
          for="toggle"
          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
        >
        </label>
      </div>
      <div class="px-4 billing" :class="{ active: billingAnnually }">
        Billed Annually
      </div>
    </div>
    <div class="flex flex-row">
      <div class="price current">
        <div>
          <h2>Starter</h2>
          <div class="subtitle active">
            <span class="value">$0</span>/forever
          </div>
          <div class="subtitle dummy">&nbsp;</div>
          <button @click="$emit('done')">Stay With starter</button>
          <ul class="features">
            <li>One Custom Style</li>
            <li>Automated Apple Watch Linking</li>
            <li>Limited to 10 instance or 5 hours of streaming</li>
          </ul>
        </div>
      </div>
      <div v-for="product in products" :key="product.id" class="price">
        <div>
          <h2>{{ product.name }}</h2>
          <div class="flex flex-col">
            <div
              v-for="price in product.prices"
              :key="price.id"
              class="subtitle"
              :class="classForPrice(price)"
            >
              <span class="value">${{ monthlyAmount(price) }}</span>
              <span>/month</span>
              <span class="billing">billed {{ billingLabel(price) }}</span>
            </div>
          </div>
          <button
            v-for="price in product.prices"
            :key="price.id"
            :class="classForButton(price)"
            @click="clickOnPrice(price)"
          >
            Upgrade to {{ product.name }}
          </button>
          <ul class="features">
            <li>Multiple Saved Styles</li>
            <li>Static Browser Source URL for OBS</li>
            <li>Unlimited Streaming</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center">
      <div>See More <a href="#">Pricing Details</a> Here.</div>
    </div>
  </div>
</template>
<script>
import { loadStripe } from '@stripe/stripe-js/pure'
export default {
  props: ['products', 'message'],
  emits: ['done'],
  data() {
    return {
      publishableKey: process.env.VUE_APP_PUBLISHABLE_KEY,
      billingMonthly: false,
    }
  },
  computed: {
    billingAnnually: {
      get: function () {
        return !this.billingMonthly
      },
      set: function (newVal) {
        this.billingMonthly = !newVal
      },
    },
  },
  methods: {
    monthlyAmount(price) {
      const subscription = price.subscription
      if (!subscription) {
        return price.amount / 100
      } else if (subscription.interval == 'year') {
        return price.amount / 100 / 12
      } else if (subscription.interval == 'month') {
        return price.amount / 100
      } else {
        return price.amount / 100
      }
    },
    billingLabel(price) {
      const subscription = price.subscription
      if (!subscription) {
        return ''
      } else if (subscription.interval == 'year') {
        return 'annually'
      } else if (subscription.interval == 'month') {
        return 'monthly'
      } else {
        return ''
      }
    },
    classForButton(price) {
      const subscription = price.subscription
      if (this.billingAnnually) {
        return { hidden: subscription.interval !== 'year' }
      } else if (this.billingMonthly) {
        return { hidden: subscription.interval !== 'month' }
      } else {
        return { hidden: subscription }
      }
    },
    classForPrice(price) {
      const subscription = price.subscription
      if (!subscription) {
        return { active: true }
      } else if (subscription.interval == 'year') {
        return { active: this.billingAnnually }
      } else if (subscription.interval == 'month') {
        return { active: this.billingMonthly }
      } else {
        return {}
      }
    },
    clickOnPrice(price) {
      this.axios
        .post(
          '/api/v1/checkout',
          {
            priceID: price.id,
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.$store.getters.token,
              'X-HEARTWITCH-CHECKOUT-CANCEL-URL' : window.location.href,
              'X-HEARTWITCH-CHECKOUT-SUCCESS-URL' : window.location.protocol + "//" + window.location.host + "/#/home/profile?session_id={CHECKOUT_SESSION_ID}"
            },
          }
        )
        .then((response) => {
          loadStripe(process.env.VUE_APP_PUBLISHABLE_KEY).then((stripe) => {
            return stripe.redirectToCheckout({ sessionId: response.data.id })
          })
        })
    },
  },
}
</script>
<style scoped>
.productsUpgrade {
  @apply bg-white z-50 m-auto rounded p-4 text-black flex flex-col;
}
.productsUpgrade > .toggle-container {
  @apply flex flex-row justify-center items-center py-2;
}
.productsUpgrade > .toggle-container .billing {
  @apply text-gray-400;
}
.productsUpgrade > .toggle-container .billing.active {
  @apply text-black;
}
.productsUpgrade > div:first-child {
  @apply py-2 text-sm;
}
.productsUpgrade > div:last-child {
  @apply py-2;
}
.productsUpgrade > div:last-child a {
  @apply text-blue-600;
}
.productsUpgrade > div:first-child h2 {
  @apply font-black text-xl;
}
.productsUpgrade .price {
  @apply w-80 h-96 flex-1 border-purple-300 rounded p-4 m-4 border-2;
}
.productsUpgrade .price.current {
  @apply border-purple-100;
}
.productsUpgrade .price h2 {
  @apply text-xl font-light pb-2;
}
.productsUpgrade .price .subtitle {
  @apply text-gray-400;
}
.productsUpgrade .price .subtitle.active {
  @apply text-black;
}
.productsUpgrade .price .subtitle::before {
  content: 'or';
  @apply text-xs italic mr-2;
}
.productsUpgrade .price .subtitle.active::before,
.productsUpgrade .price .subtitle.dummy::before {
  content: '';
  @apply m-0;
}
.productsUpgrade .price .subtitle.active {
  @apply text-xl font-light py-4 order-first;
}
.productsUpgrade .price .subtitle.active .value {
  @apply text-4xl;
}
.productsUpgrade .price .subtitle .billing {
  @apply text-xs italic;
}
.productsUpgrade .price .subtitle.active .billing {
  @apply hidden;
}
.productsUpgrade .price button {
  @apply font-bold border rounded px-4 p-2 my-4 w-full bg-purple-600 text-white;
}
.productsUpgrade .price.current button {
  @apply bg-white text-black;
}
.productsUpgrade .price .features li {
  @apply list-disc list-inside text-sm;
}
/* CHECKBOX TOGGLE SWITCH */
/* @apply rules for documentation, these do not work as inline style */
.toggle-checkbox:checked {
  @apply right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply bg-green-400;
  background-color: #68d391;
}
</style>
