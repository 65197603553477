<template>
  <div class="mt-4">
    <div class="pb-3">
      Welcome <span class="font-bold">{{ email }}</span>
      <button class="lowercase italic float-right mr-8 px-4" @click="logout">
        Logout
      </button>
    </div>
    <hr class="py-4 border-gray-700" />
    <router-view />
  </div>
</template>
<script>
export default {
  middleware: 'auth',
  data() {
    return {
      email: '',
      shortId: '',
      token: null,
      configuring: false,
      activityType: 'initial',
    }
  },
  mounted() {
    this.$store.commit('initialize')
    this.email = this.$store.getters.email
  },
  methods: {
    beginAppleCKSignIn() {},
    showConfigure() {
      this.configuring = true
    },
    showFullscreen() {
      if (this.activityType === 'full') {
        this.activityType = 'initial'
      } else {
        this.activityType = 'full'
      }
    },
    cancel() {
      this.$disconnect()
      this.shortId = ''
      this.$refs.workout.cancel()
      this.$store.commit('endWorkoutData')
    },
    startWorkout() {
      this.$refs.workout.start()
    },
    logout() {
      this.$store.commit('clear')
      this.$router.push({ path: '/' })
    },
  },
}
</script>
